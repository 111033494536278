import { Box, keyframes, useMediaQuery, useTheme } from "@mui/material"
import React, { PropsWithChildren } from "react"
import DevTool from "../components/DevTool/DevTool"
import { styled } from "@mui/material"
import { useLocation } from "react-router-dom"

const AppWrapper = styled("main")(({ theme }) => {
  return {
    background: theme.palette.gradient?.layout,
    position: "relative",
    overflowX: "hidden",
  }
})

const LayoutWrapper = styled("div")(({ theme }) => {
  return {
    minHeight: "100vh",
    minWidth: "100vw",
    position: "relative",
  }
})

const SmallTree = styled("div")(({ theme }) => {
  const treeBackgroundA = "/layout/tree_a.webp"
  return {
    width: "400vw",
    height: "96%",
    minHeight: "166vh",
    background: `url(${treeBackgroundA})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    right: "-170%",
    bottom: "0px",
    zIndex: "1",
    transform: "scale(0.4)",
    transformOrigin: "center bottom",
  }
})

const FarmSmallTree = styled("div")(({ theme }) => {
  const treeBackgroundA = "/layout/tree_a.webp"
  return {
    width: "206vw",
    height: "100vh",
    minHeight: "160vh",
    background: `url(${treeBackgroundA})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    right: "-64%",
    bottom: "-20%",
    zIndex: "1",
    transform: "scale(0.45) translateX(-20%)",
    // transformOrigin: "center bottom",
  }
})

const TreeA = styled("div")(({ theme }) => {
  const treeBackgroundA = "/layout/tree_a.webp"
  return {
    width: "100%",
    height: "91%",
    minHeight: "160vh",
    background: `url(${treeBackgroundA})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    right: "-46%",
    bottom: "55px",
    zIndex: "1",
    transform: "scale(0.4)",
    transformOrigin: "center bottom",
  }
})

const TreeB = styled("div")(({ theme }) => {
  const treeBackgroundB = "/layout/tree_b.webp"
  return {
    width: "100%",
    height: "100%",
    minHeight: "190vh",
    background: `url(${treeBackgroundB})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    left: "-32.5%",
    bottom: "40px",
    zIndex: "1",
    transform: "scale(0.4)",
    transformOrigin: "center bottom",
  }
})

const FarmTreeA = styled("div")(({ theme }) => {
  const treeBackgroundA = "/layout/tree_a.webp"
  return {
    width: "100%",
    height: "30%",
    minHeight: "152vh",
    background: `url(${treeBackgroundA})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    right: "-46%",
    bottom: "-439px",
    zIndex: "1",
    transform: "scale(0.4)",
  }
})

const FarmTreeB = styled("div")(({ theme }) => {
  const treeBackgroundB = "/layout/tree_b.webp"
  return {
    width: "100%",
    height: "34%",
    minHeight: "166vh",
    background: `url(${treeBackgroundB})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    left: "-32.5%",
    bottom: "-485px",
    zIndex: "1",
    transform: "scale(0.4)",
  }
})

const GrassA = styled("div")(({ theme }) => {
  const grassBg = "/layout/grass.png"
  return {
    background: `url(${grassBg})`,
    position: "absolute",
    width: "240px",
    height: "240px",
    overflow: "hidden",
    transform: "scale(0.7)",
    zIndex: "6",
    bottom: "-36px",
    left: "160px",
  }
})

const GrassB = styled("div")(({ theme }) => {
  const grassBg = "/layout/grass.png"
  return {
    background: `url(${grassBg})`,
    position: "absolute",
    width: "240px",
    height: "240px",
    overflow: "hidden",
    transform: "scale(0.5)",
    zIndex: "5",
    bottom: "-30px",
    left: "240px",
  }
})

const GrassC = styled("div")(({ theme }) => {
  const grassBg = "/layout/grass.png"
  return {
    background: `url(${grassBg})`,
    position: "absolute",
    width: "240px",
    height: "240px",
    overflow: "hidden",
    transform: "scale(0.65)",
    zIndex: "5",
    bottom: "-36px",
    right: "115px",
  }
})

const Mountains = styled("div")(({ theme }) => {
  const mountainBackground = "/layout/mountains.webp"
  return {
    width: "140vw",
    height: "100vh",
    background: `url(${mountainBackground})`,
    position: "absolute",
    bottom: "-35px",
    zIndex: "0",
    transform: "scale(0.75) translateX(-20%)",
    mixBlendMode: "luminosity",
  }
})

const BottomLayout = styled("div")(({ theme }) => {
  const stonesBackground = "/layout/ground.webp"
  return {
    background: `url(${stonesBackground})`,
    backgroundRepeat: "repeat-x",
    backgroundSize: "1440px",
    zIndex: "50",
    height: "90px",
    width: "100%",
    pointerEvents: "none",
    position: "absolute",
    left: "0px",
    bottom: "0px",
  }
})

const Slide = keyframes`
0% {
  transform:  translateX(0px);
}
25% {
  transform:  translateX(30px);
}
50% { 
  transform:  translateX(60px);
 }
75% {
  transform:  translateX(30px);
} 
100% {
  transform:  translateX(0px); 
}
`

const CloudOneDiv = styled("div")(({ theme }) => {
  return {
    top: "15%",
    left: "5%",
    zIndex: "0",
    position: "absolute",
    animation: `${Slide} 15s infinite linear`,
  }
})

const CloudTwoDiv = styled("div")(({ theme }) => {
  return {
    top: "30%",
    right: "5%",
    zIndex: "0",
    position: "absolute",
    animation: `${Slide} 15s infinite linear`,
  }
})

const CloudThreeDiv = styled("div")(({ theme }) => {
  return {
    top: "45%",
    left: "5%",
    zIndex: "0",
    position: "absolute",
    animation: `${Slide} 15s infinite linear`,
  }
})

export default function AppContainer({ children }: PropsWithChildren<unknown>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { pathname } = useLocation()
  const isFarmPage = "/farm"
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <AppWrapper>
      <LayoutWrapper sx={{ padding: isSmDown ? "90px 0px" : "120px 0px" }}>
        {pathname === isFarmPage ? (
          isSmDown ? (
            <>
              <GrassA />
              <GrassB />
              <GrassC />
              <FarmSmallTree />
            </>
          ) : (
            <>
              <GrassA />
              <GrassB />
              <GrassC />
              <FarmTreeA />
              <FarmTreeB />

              <CloudOneDiv>
                <img
                  src="/layout/cloud.png"
                  width={89.6}
                  height={51.2}
                  alt="Cloud Image"
                />
              </CloudOneDiv>

              <CloudTwoDiv>
                <img
                  src="/layout/cloud.png"
                  width={179.2}
                  height={102.4}
                  alt="Cloud Image"
                />
              </CloudTwoDiv>

              <CloudThreeDiv>
                <img
                  src="/layout/cloud.png"
                  width={156.8}
                  height={89.6}
                  alt="Cloud Image"
                />
              </CloudThreeDiv>
            </>
          )
        ) : isSmDown ? (
          <SmallTree />
        ) : (
          <>
            <TreeA />
            <TreeB />
          </>
        )}
        {children}
        <Mountains />
      </LayoutWrapper>
      <BottomLayout />
      <DevTool />
    </AppWrapper>
  )
}

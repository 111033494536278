import { OriginColorVariant } from "../types"
import { PaletteOptions } from "@mui/material"
import { createGradient2 } from "../utils/createGradient2"

// Define custom color types

type GradientsPaletteOptions = {
  logo: string
  gold: string
  layout: string
} & { [K in OriginColorVariant]?: string }

interface OtherColorTypes {
  divider: string
  border: string
  blue1: string
  blue2: string
  blue3: string
  blue4: string
  blue5: string
  blue6: string
  blue7: string
  blue8: string
  blue9: string
  blue10: string
  blue11: string
  blue12: string
  indigo1: string
  indigo2: string
  indigo3: string
  indigo4: string
  indigo5: string
  indigo6: string
  indigo7: string
  indigo8: string
  indigo9: string
  indigo10: string
  indigo11: string
  indigo12: string
  sand1: string
  sand2: string
  sand3: string
  sand4: string
  sand5: string
  sand6: string
  sand7: string
  sand8: string
  sand9: string
  sand10: string
  sand11: string
  sand12: string
  red1: string
  red2: string
  red3: string
  red4: string
  red5: string
  red6: string
  red7: string
  red8: string
  red9: string
  red10: string
  red11: string
  red12: string
  green1: string
  green2: string
  green3: string
  green4: string
  green5: string
  green6: string
  green7: string
  green8: string
  green9: string
  green10: string
  green11: string
  green12: string
  pink1: string
  pink2: string
  pink3: string
  pink4: string
  pink5: string
  pink6: string
  pink7: string
  pink8: string
  pink9: string
  pink10: string
  pink11: string
  pink12: string
  title: string
  header: string
  caption: string
  card: string
  cardColored: string
  btn: string
  btnHover: string
  display: string
  btnJoyful: string
}
declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    alert?: {
      content?: string
      background?: string
    }
  }
  interface PaletteColor {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
    alert?: {
      content?: string
      background?: string
    }
  }
  interface Palette {
    gradient?: GradientsPaletteOptions
    other: OtherColorTypes
  }
  interface PaletteOptions {
    gradient?: GradientsPaletteOptions
    other: OtherColorTypes
  }
}

const GREY_TONES = {
  100: "#E4E4E4",
  200: "#A7A7A7",
  300: "#7D7D7D",
  500: "#404040",
  700: "#252525",
}

const lightPalette: PaletteOptions | undefined = {
  mode: "light",
  primary: {
    main: "#474799",
    dark: "#363681",
    light: "#7272BA",
  },
  secondary: {
    main: "#47998F",
    dark: "#337F77",
    light: "#78C4BB",
  },
  info: {
    main: "#474799",
    alert: {
      background: "#C9B8FB",
    },
  },
  background: {
    default: "#FFFFFF",
    paper: "#EAEAEA",
  },
  action: {
    hover: "rgba(0, 0, 0, 0.1)",
    active: "#474799",
    disabled: "#A7A7A7",
  },
  success: {
    main: "#06D7D7",
    dark: "#037777",
    light: "#83EBEB",
    alert: {
      background: "#E6FFFF",
    },
  },
  error: {
    main: "#FB5A5A",
    dark: "#68282F",
    light: "#F2C795",
    alert: {
      background: "#FEECEB",
    },
  },
  warning: {
    main: "#EDA554",
    dark: "#EA943D",
    light: "#FFEA91",
    alert: {
      content: "#000000",
      background: "#FFF3C8",
    },
  },
  text: {
    primary: "#000000",
    secondary: "#404040",
    disabled: "#A7A7A7",
  },
  divider: "#474799",
  grey: GREY_TONES,
  other: {
    divider: "#474799",
    border: "#7D7D7D",
    blue1: "hsl(206, 100%, 99.2%)",
    blue2: "hsl(210, 100%, 98%)",
    blue3: "hsl(209, 100%, 96.5%)",
    blue4: "hsl(210, 98.8%, 94%)",
    blue5: "hsl(209, 95%, 90.1%)",
    blue6: "hsl(209, 81.2%, 84.5%)",
    blue7: "hsl(208, 77.5%, 76.9%)",
    blue8: "hsl(206, 81.9%, 65.3%)",
    blue9: "hsl(206, 100%, 50%)",
    blue10: "hsl(208, 100%, 47.3%)",
    blue11: "hsl(211, 100%, 43.2%)",
    blue12: "hsl(211, 100%, 15%)",
    indigo1: "hsl(225, 60%, 99.4%)",
    indigo2: "hsl(223, 100%, 98.6%)",
    indigo3: "hsl(223, 98.4%, 97.1%)",
    indigo4: "hsl(223, 92.9%, 95%)",
    indigo5: "hsl(224, 87.1%, 92%)",
    indigo6: "hsl(224, 81.9%, 87.8%)",
    indigo7: "hsl(225, 77.4%, 82.1%)",
    indigo8: "hsl(226, 75.4%, 74.5%)",
    indigo9: "hsl(226, 70%, 55.5%)",
    indigo10: "hsl(226, 58.6%, 51.3%)",
    indigo11: "hsl(226, 55%, 45%)",
    indigo12: "hsl(226, 62%, 17%)",
    sand1: "hsl(50, 20%, 99%)",
    sand2: "hsl(60, 7.7%, 97.5%)",
    sand3: "hsl(59, 6.5%, 95.1%)",
    sand4: "hsl(58, 6.1%, 92.9%)",
    sand5: "hsl(57, 6%, 90.7%)",
    sand6: "hsl(56, 5.9%, 88.4%)",
    sand7: "hsl(55, 5.9%, 85.2%)",
    sand8: "hsl(51, 6%, 77.1%)",
    sand9: "hsl(50, 2%, 55.7%)",
    sand10: "hsl(55, 1.7%, 51.9%)",
    sand11: "hsl(50, 2%, 43.1%)",
    sand12: "hsl(50, 6%, 10%)",
    red1: "hsl(353, 23%, 9.8%)",
    red2: "hsl(357, 34.4%, 12%)",
    red3: "hsl(356, 43.4%, 16.4%)",
    red4: "hsl(356, 47.6%, 19.2%)",
    red5: "hsl(356, 51.1%, 21.9%)",
    red6: "hsl(356, 55.2%, 25.9%)",
    red7: "hsl(357, 60.2%, 31.8%)",
    red8: "hsl(358, 65%, 40.4%)",
    red9: "hsl(358, 75%, 59%)",
    red10: "hsl(358, 85.3%, 64%)",
    red11: "hsl(358, 100%, 69.5%)",
    red12: "hsl(351, 89%, 96%)",
    green1: "hsl(146, 30%, 7.4%)",
    green2: "hsl(155, 44.2%, 8.4%)",
    green3: "hsl(155, 46.7%, 10.9%)",
    green4: "hsl(154, 48.4%, 12.9%)",
    green5: "hsl(154, 49.7%, 14.9%)",
    green6: "hsl(154, 50.9%, 17.6%)",
    green7: "hsl(153, 51.8%, 21.8%)",
    green8: "hsl(151, 51.7%, 28.4%)",
    green9: "hsl(151, 55%, 41.5%)",
    green10: "hsl(151, 49.3%, 46.5%)",
    green11: "hsl(151, 50%, 53.2%)",
    green12: "hsl(137, 72%, 94%)",
    pink1: "hsl(318, 25%, 9.6%)",
    pink2: "hsl(319, 32.2%, 11.6%)",
    pink3: "hsl(319, 41%, 16%)",
    pink4: "hsl(320, 45.4%, 18.7%)",
    pink5: "hsl(320, 49%, 21.1%)",
    pink6: "hsl(321, 53.6%, 24.4%)",
    pink7: "hsl(321, 61.1%, 29.7%)",
    pink8: "hsl(322, 74.9%, 37.5%)",
    pink9: "hsl(322, 65%, 54.5%)",
    pink10: "hsl(323, 72.8%, 59.2%)",
    pink11: "hsl(325, 90%, 66.4%)",
    pink12: "hsl(322, 90%, 95.8%)",
    title: "#18181B",
    header: "rgba(0 , 0, 0, 0.1)",
    caption: "hsl(50, 6%, 10%)",
    btn: "#111",
    btnHover: "#222",
    card: "#ffffff",
    cardColored: "#E9D5FF",
    display: "#F472B6",
    btnJoyful: "#F472B6",
  },
  gradient: {
    primary: createGradient2("#7272BA", "#474799"),
    secondary: createGradient2("#78C4BB", "#47998F"),
    logo: "linear-gradient(90deg, #78C4BB 0%, #4F9994 22%, #363681 86%)",
    gold: "linear-gradient(90deg, #CEAA7B 0%, #FCF5DD 50%, #BE893D 100%)",
    layout:
      "linear-gradient(180deg, #E9D5FF 0%, #E9D5FF 6.67%, #EAD3FD 13.33%, #EAD1FB 20%, #EBCEF9 26.67%, #EDCBF5 33.33%, #EEC6F1 40%, #F0C1EC 46.67%, #F2BCE7 53.33%, #F4B7E2 60%, #F5B2DE 66.67%, #F7AFDA 73.33%, #F8ACD8 80%, #F8AAD6 86.67%, #F9A8D4 93.33%, #F9A8D4 100%);",
  },
}

const darkPalette: PaletteOptions | undefined = {
  mode: "dark",
  primary: {
    main: "#474799",
    dark: "#363681",
    light: "#7272BA",
  },
  secondary: {
    main: "#47998F",
    dark: "#337F77",
    light: "#78C4BB",
  },
  info: {
    main: "#4B11F2",
    dark: "#2F099F",
    light: "#9B91FF",
  },

  action: {
    hover: "rgba(0, 0, 0, 0.2)",
    active: "#474799",
    disabled: "#A7A7A7",
    disabledBackground: "#404040",
    hoverOpacity: 0.5,
    selected: "#474799",
  },
  success: {
    main: "#06D7D7",
    dark: "#037777",
    light: "#83EBEB",
    alert: {
      background: "#E6FFFF",
    },
  },
  error: {
    main: "#FB5A5A",
    dark: "#68282F",
    light: "#FDA49A",
    alert: {
      background: "#FEECEB",
    },
  },
  warning: {
    main: "#EDA554",
    dark: "#EA943D",
    light: "#F2C795",
    alert: {
      content: "#000000",
      background: "#FFF3C8",
    },
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#E4E4E4",
    disabled: "#7D7D7D",
  },
  background: {
    default: "#181818",
    paper: "#303030",
  },
  divider: "#7272BA",
  other: {
    divider: "#7272BA",
    blue1: "hsl(212, 35%, 9.2%)",
    blue2: "hsl(216, 50%, 11.8%)",
    blue3: "hsl(214, 59.4%, 15.3%)",
    blue4: "hsl(214, 65.8%, 17.9%)",
    blue5: "hsl(213, 71.2%, 20.2%)",
    blue6: "hsl(212, 77.4%, 23.1%)",
    blue7: "hsl(211, 85.1%, 27.4%)",
    blue8: "hsl(211, 89.7%, 34.1%)",
    blue9: "hsl(206, 100%, 50%)",
    blue10: "hsl(209, 100%, 60.6%)",
    blue11: "hsl(210, 100%, 66.1%)",
    blue12: "hsl(206, 98%, 95.8%)",
    indigo1: "hsl(229, 24%, 10%)",
    indigo2: "hsl(230, 36.4%, 12.9%)",
    indigo3: "hsl(228, 43.3%, 17.5%)",
    indigo4: "hsl(227, 47.2%, 21%)",
    indigo5: "hsl(227, 50%, 24.1%)",
    indigo6: "hsl(226, 52.9%, 28.2%)",
    indigo7: "hsl(226, 56%, 34.5%)",
    indigo8: "hsl(226, 58.2%, 44.1%)",
    indigo9: "hsl(226, 70%, 55.5%)",
    indigo10: "hsl(227, 75.2%, 61.6%)",
    indigo11: "hsl(228, 100%, 75.9%)",
    indigo12: "hsl(226, 83%, 96.3%)",
    sand1: "hsl(61, 2%, 8.3%)",
    sand2: "hsl(60, 3.7%, 10.6%)",
    sand3: "hsl(58, 3.7%, 13.1%)",
    sand4: "hsl(57, 3.6%, 15.3%)",
    sand5: "hsl(56, 3.7%, 17.4%)",
    sand6: "hsl(55, 3.7%, 19.9%)",
    sand7: "hsl(53, 3.7%, 23.6%)",
    sand8: "hsl(50, 3.8%, 30.6%)",
    sand9: "hsl(50, 4%, 42.7%)",
    sand10: "hsl(52, 3.1%, 48.3%)",
    sand11: "hsl(50, 4%, 61.8%)",
    sand12: "hsl(56, 4%, 92.8%)",
    red1: "hsl(353, 23%, 9.8%)",
    red2: "hsl(357, 34.4%, 12%)",
    red3: "hsl(356, 43.4%, 16.4%)",
    red4: "hsl(356, 47.6%, 19.2%)",
    red5: "hsl(356, 51.1%, 21.9%)",
    red6: "hsl(356, 55.2%, 25.9%)",
    red7: "hsl(357, 60.2%, 31.8%)",
    red8: "hsl(358, 65%, 40.4%)",
    red9: "hsl(358, 75%, 59%)",
    red10: "hsl(358, 85.3%, 64%)",
    red11: "hsl(358, 100%, 69.5%)",
    red12: "hsl(351, 89%, 96%)",
    green1: "hsl(146, 30%, 7.4%)",
    green2: "hsl(155, 44.2%, 8.4%)",
    green3: "hsl(155, 46.7%, 10.9%)",
    green4: "hsl(154, 48.4%, 12.9%)",
    green5: "hsl(154, 49.7%, 14.9%)",
    green6: "hsl(154, 50.9%, 17.6%)",
    green7: "hsl(153, 51.8%, 21.8%)",
    green8: "hsl(151, 51.7%, 28.4%)",
    green9: "hsl(151, 55%, 41.5%)",
    green10: "hsl(151, 49.3%, 46.5%)",
    green11: "hsl(151, 50%, 53.2%)",
    green12: "hsl(137, 72%, 94%)",
    pink1: "hsl(318, 25%, 9.6%)",
    pink2: "hsl(319, 32.2%, 11.6%)",
    pink3: "hsl(319, 41%, 16%)",
    pink4: "hsl(320, 45.4%, 18.7%)",
    pink5: "hsl(320, 49%, 21.1%)",
    pink6: "hsl(321, 53.6%, 24.4%)",
    pink7: "hsl(321, 61.1%, 29.7%)",
    pink8: "hsl(322, 74.9%, 37.5%)",
    pink9: "hsl(322, 65%, 54.5%)",
    pink10: "hsl(323, 72.8%, 59.2%)",
    pink11: "hsl(325, 90%, 66.4%)",
    pink12: "hsl(322, 90%, 95.8%)",
    title: "white",
    header: "rgba(0 , 0, 0, 0.1)",
    caption: "white",
    card: "#333333",
    cardColored: "#831843",
    border: "#1a1a1a",
    btn: "#111",
    btnHover: "#222",
    display: "#EC4899",
    btnJoyful: "#EC4899",
  },
  grey: GREY_TONES,
  gradient: {
    primary: createGradient2("#7272BA", "#474799"),
    secondary: createGradient2("#78C4BB", "#47998F"),
    logo: "linear-gradient(90deg, #78C4BB 0%, #4F9994 22%, #363681 86%)",
    gold: "linear-gradient(90deg, #CEAA7B 0%, #FCF5DD 50%, #BE893D 100%)",
    layout:
      "linear-gradient(180deg, #9D174D 0%, #9E184E 6.67%, #A01C52 13.33%, #A52358 20%, #AB2C61 26.67%, #B2396C 33.33%, #BC477A 40%, #C65789 46.67%, #D06898 53.33%, #DA78A7 60%, #E486B5 66.67%, #EB93C0 73.33%, #F19CC9 80%, #F6A3CF 86.67%, #F8A7D3 93.33%, #F9A8D4 100%);",
  },
}
export default { lightPalette, darkPalette }
